.chamadas-historico-search-container {
  padding: 70px 10px;
}
.chamadas-historico-search-header {
  background-color: steelblue;
}
.chamadas-historico-search-header,
.chamadas-historico-search-buttons,
.chamadas-historico-search-warning,
.chamadas-historico-search-content {
  border-left: 1px solid #90a4ae;
  border-right: 1px solid #90a4ae;
  border-bottom: 1px solid #90a4ae;
  padding: 8px 20px;
}
.chamadas-historico-search-header-text {
  font-size: 1.4rem;
  color: white;
}
.chamadas-historico-search-buttons {
  display: flex;
  flex-direction: row;
  background-color: blanchedalmond;
  padding-top: 15px;
  padding-bottom: 13px;
}
.chamadas-historico-search-button {
  border: 1px solid #cecece;
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 4px;
}

.chamadas-historico-search-button-sair {
  border: 1px solid rgb(206, 206, 206);
  min-width: 50px;
  background-color: #78909c;
  color: #fff;
}

.chamadas-historico-search-button-incluir-cliente {
  background-color: #025a6c;
  min-width: 80px;
  color: #fff;
}

.chamadas-historico-search-button-salvar {
  background-color: #4682b4;
  min-width: 70px;
  color: #fff;
}

.chamadas-historico-search-button:hover,
.chamadas-historico-search-button-sair:hover {
  background-color: navy;
}
.chamadas-historico-search-warning-text {
  font-size: 0.8rem;
  color: black;
}
.chamadas-historico-search-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.chamadas-historico-search-input-group {
  margin: 5px 30px 15px 0;
  display: flex;
  flex-direction: column;
}
.chamadas-historico-search-content
  .chamadas-historico-search-input-group
  .chamadas-historico-search-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: navy;
  margin-bottom: 0;
}
.chamadas-historico-search-content 
  .chamadas-historico-search-input {
  padding: 5px;
  background-color: #fff;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  font-family: "roboto";
  font-size: 0.9rem;
  color: #000;
}
.chamadas-historico-search-content 
  .chamadas-historico-search-input:hover {
  border-width: 2px;
  border-color: black;
}
.chamadas-historico-search-content 
  .chamadas-historico-search-input:focus {
  border-width: 3px;
  border-color: #2684ff;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-info-container {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 3px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 3px 10px 1px;
  box-shadow: 0 1px 3px;
  max-width: 800px;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-standard-container {
  background-color: #ffebcd;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-contrato-container {
  background-color: yellow;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-obs-container {
  background-color: white;
}

.chamadas-historico-search-content 
  .chamadas-historico-search-client-info {
  color: maroon;
  font-size: 0.9rem;
}

#chamadas-historico-search td,
#chamadas-historico-search th {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.tool-tip {
  font-size: 0.8rem;
  background-color: #00006e !important;
  border-radius: 10px;
}
