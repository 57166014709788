@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: Roboto, Rubik, sans-serif !important;
}

body {
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased !important;
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body,
input,
select,
textarea,
button {
  font-family: Roboto, sans-serif !important;
}

input,
select,
textarea {
  padding: 0 0 0 5px;
}

/* .oficina-form footer button { */
.btn-man-forn {
  width: 55%;
  min-width: 100px;
  border: 0;
  box-shadow: 0 4px 4px #000;
  border-radius: 10px;
  background: rgb(53, 53, 253);
  padding: 15px 1px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  cursor: point;
  transition: background 0.5s;
}

/* .oficina-form footer button:hover { */
.btn-man-forn:hover {
  background: rgb(0, 0, 78);
}

.arrow-back-abs {
  /* position: absolute; */
  font-size: 5rem;
  /* float: inline-start; */
  cursor: pointer;
  padding-bottom: 0 !important;
}

.arrow-back {
  /* position: absolute; */
  font-size: 4rem;
  float: inline-start;
  cursor: pointer;
}

.app-content {
  display: grid;
  grid-template-columns: 1fr 8fr;
  grid-template-rows: 100%;
}

.column-left {
  text-align: left;
}
.column-right {
  text-align: right;
}

#navbar .menu {
  margin-top: 70px;
}

#navbar .logged-user {
  color: wheat;
}

#navbar .qtty-items {
  font-size: 0.7rem;
  margin-right: 15px;
}

#navbar .navbar-brand {
  font-size: 1.7rem;
}

#navbar .navbar-address {
  font-size: 0.8rem;
  color: yellow;
  margin-left: 20px;
  height: 45px;
}

#navbar .navbar-address-click {
  cursor: pointer;
  font-style: italic;
  text-decoration: underline;
  color: aquamarine;
}

#navbar .btn-conta {
  font-size: 0.8rem;
  color: red;
}
#navbar .btn-cart {
  font-size: 1.4rem;
  color: yellow;
  cursor: pointer;
}

#navbar .btn-logout {
  padding: 5px 13px 5px 13px;
  border-radius: 12px;
  background: wheat;
  font-size: 0.8rem;
}

.app-aside {
  min-height: 100vh;
  /* height: 40vh;  para tamanho mobile */
  background: rgb(36, 36, 35);
}

.container-aside {
  padding: 15vh 10px 40vh;
  color: cyan;
}

.container-aside p {
  cursor: pointer;
}

#login-box {
  padding: 100px 0px;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 auto;
  width: 40vw;
  min-width: 295px;
  /* width: 480px; */
  /* height: 420px; */
  height: 100vh;
  color: blue;
}

#login-box .login-group label {
  margin-top: 1.5rem;
}

#login-box .login-field {
  border: none;
  background: rgba(0, 0, 0, 0.22);
  /* color: #fdfdfd; */
  color: black;
  padding: 1rem;
  font-size: 1.2rem;
  border-radius: 7px;
  width: 100%;
  transition: all 0.1s linear;
}

#login-box ::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

#login-box :-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

#login-box ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

#login-box .login-field:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  outline: none;
}

#login-box .login-error {
  color: red;
}

#login-box .login-error-msg {
  color: red;
}

#login-box .login-btn {
  background: #00cc99;
  color: #fdfdfd;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  margin-top: 2rem;
}

#login-box .login-btn:hover {
  cursor: pointer;
}

.tabela-sac-container {
  padding: 70px 10px;
}
.tabela-sac-header {
  background-color: steelblue;
}
.tabela-sac-header,
.tabela-sac-buttons,
.tabela-sac-warning,
.tabela-sac-content {
  border-left: 1px solid #90a4ae;
  border-right: 1px solid #90a4ae;
  border-bottom: 1px solid #90a4ae;
  padding: 8px 20px;
}
.tabela-sac-header-text {
  font-size: 1.4rem;
  color: white;
}

.tabela-sac-cliente-mark {
  font-weight: bold;
  color: white;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  padding: 2px;
}
.tabela-sac-contrato-mark {
  background-color: #0d47a1;
}
.tabela-sac-restricao-mark {
  background-color: #ff0000;
}

.tabela-sac-buttons {
    display: flex;
  flex-direction: row;
  background-color: blanchedalmond;
  padding-top: 15px;
  padding-bottom: 13px;
}
.tabela-sac-button {
  border: 1px solid rgb(206, 206, 206);
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  background-color: #78909c;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 4px;
}
.tabela-sac-button:hover {
  background-color: navy;
}
.tabela-sac-warning-text {
  font-size: 0.8rem;
  color: black;
}
.tabela-sac-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tabela-sac-input-group {
  margin: 5px 30px 15px 0;
  display: flex;
  flex-direction: column;
}
.tabela-sac-content .tabela-sac-input-group .tabela-sac-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: navy;
  margin-bottom: 0;
}
.tabela-sac-content .tabela-sac-input-group .tabela-sac-input {
  padding: 5px;
  border-radius: 3px;
  border-color: silver;
  box-shadow: 2px 2px 0 grey;
  background-color: #fff;
}

.tabela-sac-button-calcular {
  margin-top: 23px;
  border: 1px solid rgb(206, 206, 206);
  width: 160px;
  padding: 19px 0 14px;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  background-color: #4682b4;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
}
.tabela-sac-button-calcular:hover {
  background-color: navy;
}
#tabela-sac .table td,
#tabela-sac .table th {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.tool-tip {
  font-size: 0.8rem;
  background-color: #00006e !important;
  border-radius: 10px;
}

#tabela-sac .btnMais {
  position: fixed;
  float: bottom;
  bottom: 25px;
  right: 15px;
  z-index: 100;
}
#tabela-sac .btnMais {
  display: inline-block;
}
#tabela-sac .btnCircular {
  border-radius: 50%;
  box-shadow: 0 4px 4px #000;
  font-size: 20px;
  padding: 15px;
  color: white;
  background-color: blue;;
}
#tabela-sac .btnCircular:hover {
  background-color: #2979ff;
}

.chamadas-form-container {
  padding: 70px 10px;
}
.chamadas-form-header {
  background-color: steelblue;
}
.chamadas-form-header,
.chamadas-form-buttons,
.chamadas-form-info,
.chamadas-form-content {
  border-left: 1px solid #90a4ae;
  border-right: 1px solid #90a4ae;
  border-bottom: 1px solid #90a4ae;
  padding: 2px 20px;
}
.chamadas-form-header-text {
  font-size: 1.4rem;
  color: white;
}
.chamadas-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: blanchedalmond;
  padding-top: 15px;
  padding-bottom: 13px;
}
.chamadas-form-button-sair {
  border: 1px solid rgb(206, 206, 206);
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  background-color: maroon;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 4px;
}
.chamadas-form-button {
  border: 1px solid rgb(206, 206, 206);
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  background-color: #2541b2;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 4px;
}
.chamadas-form-button:hover,
.chamadas-form-button-sair:hover {
  background-color: black;
  color: yellow;
}

.chamadas-form-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.chamadas-form-info-col,
.chamadas-form-input-group {
  margin: 5px 30px 15px 0;
  display: flex;
  flex-direction: column;
}
.chamadas-form-input-group-small {
  margin: 5px 30px 0 0;
  display: flex;
  flex-direction: column;
}
.chamadas-form-content .chamadas-form-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: blue;
  margin-bottom: 0;
}
.chamadas-form-content .chamadas-form-input {
  padding: 5px;
  background-color: #fff;
  /* border-color: #cccccc; */
  border-color: grey;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}
.chamadas-form-content .chamadas-form-input:hover {
  border-width: 1px;
  border-color: black;
}
.chamadas-form-content .chamadas-form-input:focus {
  border-width: 3px;
  border-color: #2684ff;
}

#chamadas-form td, #chamadas-form th {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.tool-tip {
  font-size: 0.8rem;
  background-color: #00006e !important;
  border-radius: 10px;
}

.chamadas-form-info-contrato {
  background-color: yellow;
  color: blue;
  margin-top: 5px;

  padding: 8px 8px 5px;
  border-radius: 8px;
  border-color: black;

  border-width: 1px;
  border-style: solid;
  font-weight: bold;

  box-shadow: 0 3px 3px #000;
}

.chamadas-form-historico-container {
  display: flex;
  flex-direction: row;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
  border-top-style: none;
}

.chamadas-form-historico-item {
  /* width: 300px; */
  text-align: center;
  border-color: coral;
  border-style: solid;
  border-width: 3px;
  border-radius: 15px;
  margin-right: 10px;
  margin-bottom: 15px;
  padding: 7px 15px 5px 15px;
  font-weight: bold;
  color: grey;
  box-shadow: 3px 6px 6px darkslategrey;
}

.chamadas-add-container {
  padding: 70px 10px;
}
.chamadas-add-header {
  background-color: steelblue;
}
.chamadas-add-header,
.chamadas-add-buttons,
.chamadas-add-warning,
.chamadas-add-content {
  border-left: 1px solid #90a4ae;
  border-right: 1px solid #90a4ae;
  border-bottom: 1px solid #90a4ae;
  padding: 8px 20px;
}
.chamadas-add-header-text {
  font-size: 1.4rem;
  color: white;
}
.chamadas-add-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: blanchedalmond;
  padding-top: 15px;
  padding-bottom: 13px;

}
.chamadas-add-button {
  border: 1px solid #cecece;
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  /* margin-right: 20px;
  margin-bottom: 4px; */
}

.chamadas-add-button-sair {
  border: 1px solid rgb(206, 206, 206);
  min-width: 50px;
  background-color: maroon;
  color: #fff;
}

.chamadas-add-button-incluir-cliente {
  background-color: #025a6c;
  min-width: 80px;
  color: #fff;
}

.chamadas-add-button-salvar {
  background-color: #2541b2;
  min-width: 70px;
  color: #fff;
  margin: 0 !important;
}  

.chamadas-add-button:hover,
.chamadas-add-button-sair:hover {
  background-color: black;
  color: yellow;
}
.chamadas-add-warning-text {
  font-size: 0.8rem;
  color: black;
}
.chamadas-add-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.chamadas-add-input-group {
  margin: 5px 30px 15px 0;
  display: flex;
  flex-direction: column;
}
.chamadas-add-content .chamadas-add-input-group .chamadas-add-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: navy;
  margin-bottom: 0;
}
.chamadas-add-content .chamadas-add-input {
  padding: 5px;
  background-color: #fff;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  font-family: "roboto";
  font-size: 0.9rem;
  color: #000;
}
.chamadas-add-content .chamadas-add-input:hover {
  border-width: 2px;
  border-color: black;
}
.chamadas-add-content .chamadas-add-input:focus {
  border-width: 3px;
  border-color: #2684ff;
}

.chamadas-add-content .chamadas-add-client-info-container {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 3px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 3px 10px 1px;
  box-shadow: 0 1px 3px ;
  max-width: 800px;
}

.chamadas-add-content .chamadas-add-client-standard-container {
  background-color: #ffebcd;
}

.chamadas-add-content .chamadas-add-client-contrato-container {
  background-color: yellow;
}

.chamadas-add-content .chamadas-add-client-obs-container {
  background-color: white;
}

.chamadas-add-content .chamadas-add-client-info {
  color: maroon;
  font-size: 0.9rem;
}

#chamadas-add td,
#chamadas-add th {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.tool-tip {
  font-size: 0.8rem;
  background-color: #00006e !important;
  border-radius: 10px;
}
.clientes-form-container {
  padding: 70px 10px;
}
.clientes-form-header {
  background-color: steelblue;
}
.clientes-form-header,
.clientes-form-buttons,
.clientes-form-warning,
.clientes-form-content {
  border-left: 1px solid #90a4ae;
  border-right: 1px solid #90a4ae;
  border-bottom: 1px solid #90a4ae;
  padding: 8px 20px;
}
.clientes-form-header-text {
  font-size: 1.4rem;
  color: white;
}
.clientes-form-buttons {
  display: flex;
  flex-direction: row;
  background-color: blanchedalmond;
  padding-top: 15px;
  padding-bottom: 13px;
}
.clientes-form-button-sair {
  border: 1px solid rgb(206, 206, 206);
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  background-color: #78909c;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 4px;
}
.clientes-form-button {
  border: 1px solid rgb(206, 206, 206);
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  background-color: #4682b4;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 4px;
}
.clientes-form-button:hover,
.clientes-form-button-sair:hover {
  background-color: navy;
}
.clientes-form-warning-text {
  font-size: 0.8rem;
  color: black;
}
.clientes-form-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.clientes-form-input-group {
  margin: 5px 30px 15px 0;
  display: flex;
  flex-direction: column;
}
.clientes-form-content .clientes-form-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: blue;
  margin-bottom: 0;
  margin-left: 3px;
}
.clientes-form-content .clientes-form-input {
  padding: 5px;
  background-color: #fff;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 0.8rem;
}
.clientes-form-content .clientes-form-input:hover {
  border-width: 2px;
  border-color: black;
}
.clientes-form-content .clientes-form-input:focus {
  border-width: 3px;
  border-color: #2684ff;
}

#clientes-form td, #clientes-form th {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.tool-tip {
  font-size: 0.8rem;
  background-color: #00006e !important;
  border-radius: 10px;
}
.chamadas-historico-search-container {
  padding: 70px 10px;
}
.chamadas-historico-search-header {
  background-color: steelblue;
}
.chamadas-historico-search-header,
.chamadas-historico-search-buttons,
.chamadas-historico-search-warning,
.chamadas-historico-search-content {
  border-left: 1px solid #90a4ae;
  border-right: 1px solid #90a4ae;
  border-bottom: 1px solid #90a4ae;
  padding: 8px 20px;
}
.chamadas-historico-search-header-text {
  font-size: 1.4rem;
  color: white;
}
.chamadas-historico-search-buttons {
  display: flex;
  flex-direction: row;
  background-color: blanchedalmond;
  padding-top: 15px;
  padding-bottom: 13px;
}
.chamadas-historico-search-button {
  border: 1px solid #cecece;
  width: 160px;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: 0 3px 3px #000;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 4px;
}

.chamadas-historico-search-button-sair {
  border: 1px solid rgb(206, 206, 206);
  min-width: 50px;
  background-color: #78909c;
  color: #fff;
}

.chamadas-historico-search-button-incluir-cliente {
  background-color: #025a6c;
  min-width: 80px;
  color: #fff;
}

.chamadas-historico-search-button-salvar {
  background-color: #4682b4;
  min-width: 70px;
  color: #fff;
}

.chamadas-historico-search-button:hover,
.chamadas-historico-search-button-sair:hover {
  background-color: navy;
}
.chamadas-historico-search-warning-text {
  font-size: 0.8rem;
  color: black;
}
.chamadas-historico-search-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.chamadas-historico-search-input-group {
  margin: 5px 30px 15px 0;
  display: flex;
  flex-direction: column;
}
.chamadas-historico-search-content
  .chamadas-historico-search-input-group
  .chamadas-historico-search-label {
  font-size: 0.9rem;
  font-weight: bold;
  color: navy;
  margin-bottom: 0;
}
.chamadas-historico-search-content 
  .chamadas-historico-search-input {
  padding: 5px;
  background-color: #fff;
  border-color: #cccccc;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  font-family: "roboto";
  font-size: 0.9rem;
  color: #000;
}
.chamadas-historico-search-content 
  .chamadas-historico-search-input:hover {
  border-width: 2px;
  border-color: black;
}
.chamadas-historico-search-content 
  .chamadas-historico-search-input:focus {
  border-width: 3px;
  border-color: #2684ff;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-info-container {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 3px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 3px 10px 1px;
  box-shadow: 0 1px 3px;
  max-width: 800px;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-standard-container {
  background-color: #ffebcd;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-contrato-container {
  background-color: yellow;
}

.chamadas-historico-search-content
  .chamadas-historico-search-client-obs-container {
  background-color: white;
}

.chamadas-historico-search-content 
  .chamadas-historico-search-client-info {
  color: maroon;
  font-size: 0.9rem;
}

#chamadas-historico-search td,
#chamadas-historico-search th {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.tool-tip {
  font-size: 0.8rem;
  background-color: #00006e !important;
  border-radius: 10px;
}

